<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

import { required } from "vuelidate/lib/validators";

/**
 * Enrol Student Form component
 */
export default {
  page: {
    title: "Matricular Aluno",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Matricular Aluno",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Alunos",
          href: "/students",
        },
        {
          text: "Matricular Aluno",
          active: true,
        },
      ],
      form: {
        user: "",
        course: "",
      },
      formMany: {
        course: "",
        groups: "",
      },
      users: [

      ],
      courses: [

      ],
      groups: [

      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      user: { required },
      course: { required },
    },
    formMany: {
      course: { required },
      groups: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :enableCad="false" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-7" data-select2-id="12">
        <form
          class="required-form"
          action="https://api-dev.fluxead.app.br/admin/enrols"
          method="post"
          enctype="multipart/form-data"
          data-select2-id="8"
        >
          <div class="card" data-select2-id="11">
            <div class="card-body" data-select2-id="10">
              <h4 class="card-title mb-3">Formulário de inscrição</h4>
              <div class="row">
                <div class="col-lg-12" data-select2-id="9">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom01">Do Utilizador <span class="required">*</span></label>
                    <multiselect
                      v-model="form.user"
                      :options="users"
                      placeholder="Selecione um usuário"
                      label="name"
                      track-by="id"
                      :class="{
                        'is-invalid':
                          typesubmit && $v.form.user.$error,
                      }"
                    ></multiselect>
                    <div
                      v-if="typesubmit && $v.form.user.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.user.required"
                        >Este campo é obrigatorio.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12" data-select2-id="9">
                  <div class="mb-4">
                    <label class="form-label" for="validationCustom01">Curso para se Inscrever <span class="required">*</span></label>
                    <multiselect
                      v-model="form.course"
                      :options="courses"
                      placeholder="Selecione um curso"
                      label="name"
                      track-by="id"
                      :class="{
                        'is-invalid':
                          typesubmit && $v.form.course.$error,
                      }"
                    ></multiselect>
                    <div
                      v-if="typesubmit && $v.form.course.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.course.required"
                        >Este campo é obrigatorio.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <button
                    type="button"
                    class="btn btn-info"
                    onclick="checkRequiredFields()"
                  >
                    Inscrever Aluno
                  </button>
                  <span class="p-1"> </span>
                  <b-button variant="light" @click="handleNewCad(false)">Cadastrar Novo Aluno</b-button>
                </div>
              </div>
            </div>
            <!-- end card body-->
          </div>
        </form>
          <!-- end card -->
        </div>
      <!-- end col-->
      <div class="col-12 col-md-5">
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <div>
                  <h4 class="card-title mb-3">Inscrição em massa</h4>
                </div>
                <div>
                  <a
                      href="https://sistema.speednow.com.br/system_files/csvs/Modelo_CSV.xlsx"
                      class="alignToTitle"
                      target="_blank"
                    >
                      <i class="fas fa-file-download"></i> Modelo CSV
                  </a>
                </div>
              </div>
              <form id="formEnrolmentMass" novalidate="novalidate" action="https://api-dev.fluxead.app.br/admin/enrols/batch">
                <div class="row">
                  <div class="col-12 form-group mb-4">
                    <span class="text-danger"><strong>* Utilize um CSV com separador ;</strong></span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input
                        type="file"
                        id="csv"
                        name="csv"
                        class="form-control"
                        placeholder="Digite o código"
                        :class="{
                          'is-invalid': submitted && $v.form.cod.$error,
                        }"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" data-select2-id="9">
                    <div class="mb-4">
                      <label class="form-label" for="validationCustom01">Curso para se Inscrever <span class="required">*</span></label>
                      <multiselect
                        v-model="formMany.course"
                        :options="courses"
                        placeholder="Selecione um usuário"
                        label="name"
                        track-by="id"
                        :class="{
                          'is-invalid':
                            typesubmit && $v.form.course.$error,
                        }"
                      ></multiselect>
                      <div
                        v-if="typesubmit && $v.form.course.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.course.required"
                          >Este campo é obrigatorio.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" data-select2-id="9">
                    <div class="mb-4">
                      <label class="form-label" for="validationCustom01">Grupos <span class="required">*</span></label>
                      <multiselect
                        v-model="formManygroup"
                        :options="groups"
                        placeholder="Selecione os grupos"
                        multiple
                        label="name"
                        track-by="id"
                        :class="{
                          'is-invalid':
                            typesubmit && $v.form.group.$error,
                        }"
                      ></multiselect>
                      <div
                        v-if="typesubmit && $v.form.group.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.group.required"
                          >Este campo é obrigatorio.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-info" id="btnSendCsv">
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <!-- end card body-->
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
