var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items,"enableCad":false}}),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-7",attrs:{"data-select2-id":"12"}},[_c('form',{staticClass:"required-form",attrs:{"action":"https://api-dev.fluxead.app.br/admin/enrols","method":"post","enctype":"multipart/form-data","data-select2-id":"8"}},[_c('div',{staticClass:"card",attrs:{"data-select2-id":"11"}},[_c('div',{staticClass:"card-body",attrs:{"data-select2-id":"10"}},[_c('h4',{staticClass:"card-title mb-3"},[_vm._v("Formulário de inscrição")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12",attrs:{"data-select2-id":"9"}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Do Utilizador "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('multiselect',{class:{
                      'is-invalid':
                        _vm.typesubmit && _vm.$v.form.user.$error,
                    },attrs:{"options":_vm.users,"placeholder":"Selecione um usuário","label":"name","track-by":"id"},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}}),(_vm.typesubmit && _vm.$v.form.user.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.user.required)?_c('span',[_vm._v("Este campo é obrigatorio.")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12",attrs:{"data-select2-id":"9"}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Curso para se Inscrever "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('multiselect',{class:{
                      'is-invalid':
                        _vm.typesubmit && _vm.$v.form.course.$error,
                    },attrs:{"options":_vm.courses,"placeholder":"Selecione um curso","label":"name","track-by":"id"},model:{value:(_vm.form.course),callback:function ($$v) {_vm.$set(_vm.form, "course", $$v)},expression:"form.course"}}),(_vm.typesubmit && _vm.$v.form.course.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.course.required)?_c('span',[_vm._v("Este campo é obrigatorio.")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button","onclick":"checkRequiredFields()"}},[_vm._v(" Inscrever Aluno ")]),_c('span',{staticClass:"p-1"}),_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.handleNewCad(false)}}},[_vm._v("Cadastrar Novo Aluno")])],1)])])])])]),_c('div',{staticClass:"col-12 col-md-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('h4',{staticClass:"card-title mb-3"},[_vm._v("Inscrição em massa")])]),_c('div',[_c('a',{staticClass:"alignToTitle",attrs:{"href":"https://sistema.speednow.com.br/system_files/csvs/Modelo_CSV.xlsx","target":"_blank"}},[_c('i',{staticClass:"fas fa-file-download"}),_vm._v(" Modelo CSV ")])])]),_c('form',{attrs:{"id":"formEnrolmentMass","novalidate":"novalidate","action":"https://api-dev.fluxead.app.br/admin/enrols/batch"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 form-group mb-4"},[_c('span',{staticClass:"text-danger"},[_c('strong',[_vm._v("* Utilize um CSV com separador ;")])])])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && _vm.$v.form.cod.$error,
                      },attrs:{"type":"file","id":"csv","name":"csv","placeholder":"Digite o código"}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12",attrs:{"data-select2-id":"9"}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Curso para se Inscrever "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('multiselect',{class:{
                        'is-invalid':
                          _vm.typesubmit && _vm.$v.form.course.$error,
                      },attrs:{"options":_vm.courses,"placeholder":"Selecione um usuário","label":"name","track-by":"id"},model:{value:(_vm.formMany.course),callback:function ($$v) {_vm.$set(_vm.formMany, "course", $$v)},expression:"formMany.course"}}),(_vm.typesubmit && _vm.$v.form.course.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.course.required)?_c('span',[_vm._v("Este campo é obrigatorio.")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12",attrs:{"data-select2-id":"9"}},[_c('div',{staticClass:"mb-4"},[_c('label',{staticClass:"form-label",attrs:{"for":"validationCustom01"}},[_vm._v("Grupos "),_c('span',{staticClass:"required"},[_vm._v("*")])]),_c('multiselect',{class:{
                        'is-invalid':
                          _vm.typesubmit && _vm.$v.form.group.$error,
                      },attrs:{"options":_vm.groups,"placeholder":"Selecione os grupos","multiple":"","label":"name","track-by":"id"},model:{value:(_vm.formManygroup),callback:function ($$v) {_vm.formManygroup=$$v},expression:"formManygroup"}}),(_vm.typesubmit && _vm.$v.form.group.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.group.required)?_c('span',[_vm._v("Este campo é obrigatorio.")]):_vm._e()]):_vm._e()],1)])]),_c('button',{staticClass:"btn btn-info",attrs:{"type":"submit","id":"btnSendCsv"}},[_vm._v(" Enviar ")])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }